import { Node, findParentNodeClosestToPos, findChildren } from "@tiptap/core";
const XbrlBlockTagNode = Node.create({
    name: "xbrlBlockTagNode",
    content: "block+",
    group: "block",
    selectable: true,
    allowGapCursor: false,
    draggable: false,
    addAttributes() {
        return {
            xbrlId: {
                default: null,
                renderHTML: (attributes) => {
                    if(attributes.xbrlId) {
                        return {
                            "data-xbrl-id": attributes.xbrlId,
                        };
                    };
                },
                parseHTML: (element) => element.getAttribute("data-xbrl-id")
            },
            cssClass: {
                default: "block-tag",
                renderHTML: (attributes) => {
                    if(attributes.xbrlId) {
                        return {
                            "class": attributes.cssClass,
                        };
                    };
                }
            },

        }
    },
    renderHTML: ({ HTMLAttributes }) => {
        return ["section", HTMLAttributes, 0];
    },
    parseHTML() {
        return [
            {
                tag: "section",
                getAttrs: (el) => {
                    let xbrlId = el.getAttribute("data-xbrl-id");
                    if (xbrlId !== null && xbrlId !== undefined && xbrlId !== "") return {};
                    return false;
                },
            },
        ];
    },
    addCommands() {
        return {
            deleteBlockTagNode: () => ({ chain, tr }) => {
                let resolvedPos = tr.selection.$from;
                let parentNode = findParentNodeClosestToPos(tr.selection.$from, (node) => {
                    return node?.type?.name === "xbrlBlockTagNode";
                });
                if (parentNode){
                    let start = resolvedPos.start(parentNode.depth);
                    let end = resolvedPos.end(parentNode.depth);
                    return chain().setTextSelection({from: start, to: end}).lift("xbrlBlockTagNode", { xbrlId: parentNode.node.attrs.xbrlId}).run();
                }
                return false;
            },
            deleteBlockTagNodeById: (id) => ({ chain, tr }) => {
                let { doc } = tr;
                let children = findChildren(doc, (node) => {
                    if (node.attrs.xbrlId === id && node.type.name === "xbrlBlockTagNode"){
                        return true;
                    }
                    return false;
                });
                if (children.length > 0){
                    let blockTagNode = children[0];
                    let resolvedPos = doc.resolve(blockTagNode.pos + 1);
                    let start = resolvedPos.start(resolvedPos.depth);
                    let end = resolvedPos.end(resolvedPos.depth);
                    return chain().setTextSelection({from: start, to: end}).lift(this.name, { xbrlId: id}).run();
                }
                return false;
            }
        }
    }
});

export default XbrlBlockTagNode;