import {findChildren, getMarksBetween} from "@tiptap/core"
export function goToTag(id, cb){
    return ({ tr, commands }) => {
        let { doc } = tr;
        let foundNodes = findChildren(doc, node => {
            let nodeAttrs = node.attrs;
            let { xbrlId } = nodeAttrs;
            if (xbrlId && xbrlId === id) {
                return true;
            }
            return false;
        });
        if (foundNodes && foundNodes.length > 0 ){
            cb();
            if (foundNodes[foundNodes.length -1 ].node.type.name === "tableRow"){
                return commands.focus(foundNodes[foundNodes.length-1].pos + 3);
            }else{
                return commands.focus(foundNodes[foundNodes.length-1].pos + 2);
            }
        }
        let allMarksWithPos = getMarksBetween(0, doc.content.size, doc);
        for (let markWithPos of allMarksWithPos){
            let markAttrs = markWithPos.mark.attrs;
            let { xbrlId } = markAttrs;
            if (xbrlId && xbrlId === id){
                cb();
                return commands.focus(markWithPos.from + 1);
            }
        }
        return false;
    }
}
