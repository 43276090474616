import TableCell from "@tiptap/extension-table-cell";

const CustomTableCell = TableCell.extend({
    addAttributes: () => {
        return {
            ...TableCell.config.addAttributes(),
            borderLeft: {
                default: true,
                renderHTML: (attributes) => {
                    let htmlAttrs = {
                        "data-border-left": attributes.borderLeft ? "true" : "false",
                    }
                    if (attributes.borderLeft) htmlAttrs.style = `border-left: ${attributes.borderWidth}px solid`;
                    return htmlAttrs;
                },
                parseHTML: (el) => {
                    let htmlAttribute = el.getAttribute("data-border-left");

                    let nodeAttribute = htmlAttribute === "true" ? true : (htmlAttribute === null || htmlAttribute === undefined) ? true : false;
                    return nodeAttribute;
                },
            },
            borderTop: {
                default: true,
                renderHTML: (attributes) => {
                    let htmlAttrs = {
                        "data-border-top": attributes.borderTop ? "true" : "false",
                    }
                    if (attributes.borderTop) htmlAttrs.style = `border-top: ${attributes.borderWidth}px solid`;
                    return htmlAttrs;
                },
                parseHTML: (el) => {
                    let htmlAttribute = el.getAttribute("data-border-top");

                    let nodeAttribute = htmlAttribute === "true" ? true : (htmlAttribute === null || htmlAttribute === undefined) ? true : false;
                    return nodeAttribute;
                },
            },
            borderRight: {
                default: true,
                renderHTML: (attributes) => {
                    let htmlAttrs = {
                        "data-border-right": attributes.borderRight ? "true" : "false",
                    }
                    if (attributes.borderRight) htmlAttrs.style = `border-right: ${attributes.borderWidth}px solid`;
                    return htmlAttrs;
                },
                parseHTML: (el) => {
                    let htmlAttribute = el.getAttribute("data-border-right");

                    let nodeAttribute = htmlAttribute === "true" ? true : (htmlAttribute === null || htmlAttribute === undefined) ? true : false;
                    return nodeAttribute;
                },
            },
            borderBottom: {
                default: true,
                renderHTML: (attributes) => {
                    let htmlAttrs = {
                        "data-border-bottom": attributes.borderBottom ? "true" : "false",
                    }
                    if (attributes.borderBottom) htmlAttrs.style = `border-bottom: ${attributes.borderWidth}px solid`;
                    return htmlAttrs;
                },
                parseHTML: (el) => {
                    let htmlAttribute = el.getAttribute("data-border-bottom");

                    let nodeAttribute = htmlAttribute === "true" ? true : (htmlAttribute === null || htmlAttribute === undefined) ? true : false;
                    return nodeAttribute;
                },
            },
            borderWidth: {
                default: 1,
                renderHTML: (attributes) => ({
                    "data-border-width": attributes.borderWidth,
                }),
                parseHTML: (el) => {
                    return el.getAttribute("data-border-width");
                },
            },
            borderColor: {
                default: "#000000",
                renderHTML: (attributes) => ({
                    "data-border-color": attributes.borderColor,
                    style: `border-color: ${attributes.borderColor}`,
                }),
                parseHTML: (el) => {
                    return el.getAttribute("data-border-color");
                },
            },
            backgroundColor: {
                default: "#ffffff",
                renderHTML: (attributes) => ({
                    "data-background-color": attributes.backgroundColor,
                    style: `background-color: ${attributes.backgroundColor}`,
                }),
                parseHTML: (el) => {
                    return el.getAttribute("data-background-color");
                },
            },
            colwidth: {
                default: [25],
                renderHTML: (attributes) => {
                    return attributes.colwidth
                        ? {
                            "data-colwidth": attributes.colwidth.join(","),
                        }
                        : null;
                },
                parseHTML: (el) => {
                    let colWidth = el.getAttribute("data-colwidth");
                    let colWidthArray = [25];
                    if (colWidth !== undefined && colWidth !== null)
                        colWidthArray = el
                            .getAttribute("data-colwidth")
                            .split(",")
                            .map((width) => parseInt(width));
                    return colWidthArray;
                },
            },
            verticalAlign: {
                default: "middle",
                renderHTML: ({ verticalAlign }) => {
                    return {
                        "data-vertical-align": verticalAlign,
                        style: `vertical-align: ${verticalAlign}`,
                    };
                },
                parseHTML: (el) => {
                    return el.getAttribute("data-vertical-align");
                },
            },
        };
    },
});

export default CustomTableCell;