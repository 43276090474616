
import TableRow from "@tiptap/extension-table-row";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import TableRowView from "../nodeViews/TableRowView";

const CustomTableRow = TableRow.extend({
    selectable: true,
    addAttributes: () => {
        return {
            xbrlId: {
                default: null,
                renderHTML: (attributes) => {
                    if(attributes.xbrlId) {
                        return {
                            "data-xbrl-id": attributes.xbrlId,
                        };
                    };
                },
                parseHTML: (element) => element.getAttribute("data-xbrl-id")
            }
        };
    },
    addNodeView() {
        return VueNodeViewRenderer(TableRowView);
    },
});

export default CustomTableRow;