import Table from "@tiptap/extension-table";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import TableView from "../nodeViews/TableView";

const CustomTable = Table.extend({
    renderHTML: ({ HTMLAttributes, node }) => {
        let rows = node.content.content;
        let colWidths = [];
        if (rows) {
            let cols = rows[0].content.content;
            if (node.attrs.savedBefore) {
                for (let col of cols) {
                    if (col.attrs.colwidth) {
                        for (let colWidth of col.attrs.colwidth) {
                            colWidths.push(colWidth);
                        }
                    }
                }
            }
            else {
                let maxWidth = 695;
                let colWidth = maxWidth / cols.length;
                for (let i = 0; i < cols.length; i++) {
                    colWidths.push(colWidth);
                }
                for (let row of rows) {
                    let columns = row.content.content;
                    for (let col of columns) {
                        col.attrs.colwidth = [colWidth];
                    }
                }
            }
        }
        return [
            "table",
            HTMLAttributes,
            [
                "colgroup",
                ...colWidths.map((colWidth) => [
                    "col",
                    { style: `width: ${colWidth}px` },
                ]),
            ],
            ["tbody", {}, 0],
        ];
    },
    addAttributes() {
        return {
            float: {
                default: null,
                renderHTML: (attrs) => {
                    if (attrs.float) {
                        return {
                            style: `float: ${attrs.float}`,
                            "data-float": attrs.float,
                        };
                    }
                },
                parseHTML: (el) => {
                    let htmlAttribute = el.getAttribute("data-float");
                    return htmlAttribute;
                },
            },
            savedBefore: {
                renderHTML: () => {
                    return {
                        "data-saved-before": "true"
                    }
                },
                parseHTML: (el) => {
                    let htmlAttr = el.getAttribute("saved-before");// to be removed after all docs are saved with the new attr name "data-saved-before"
                    let dataHtmlAttr = el.getAttribute("data-saved-before");
                    if (htmlAttr || dataHtmlAttr) {
                        return "true";
                    }
                },
            },
            xbrlId: {
                default: null,
                renderHTML: (attrs) => {
                    if(attrs.xbrlId) {
                        return {
                            "data-xbrl-id": attrs.xbrlId,
                        };
                    };
                },
                parseHTML: (element) => {
                    let htmlAttrs = element.getAttribute("data-xbrl-id");
                    return htmlAttrs;
                }
            }
        };
    },
    addCommands() {
        return {
            ...Table.config.addCommands(),
            insertFullWidthTable: ({ rows, cols, withHeaderRow }) => ({
                chain,
            }) => {
                return chain()
                    .insertTable({
                        rows,
                        cols,
                        withHeaderRow,
                    })
                    .stretchTable()
                    .updateAttributes("table", {
                        savedBefore: true
                    })
                    .run();
            },
            stretchTable: () => ({ chain, state, editor, commands }) => {
                return commands.command(({ tr }) => {
                    let { doc } = tr;
                    let resolvedPos = doc.resolve(state.selection.from);
                    let absoluteTablePos = resolvedPos.before(2);

                    let pageAttributes = editor.getAttributes("pageNode");
                    let maxWidth;
                    if (pageAttributes.class === "page") {
                        maxWidth = 695;
                    } else {
                        maxWidth = 986;
                    }
                    return chain().command(() => {
                        let tableNode = doc.nodeAt(absoluteTablePos);
                        let cols =
                            tableNode.content.content[0].content.childCount;
                        return chain()
                            .setNodeSelection(absoluteTablePos)
                            .focus()
                            .updateAttributes("tableCell", {
                                colwidth: [maxWidth / cols],
                            });
                    });
                });
            }
        };
    },
    addNodeView() {
        return VueNodeViewRenderer(TableView);
    },
});

export default CustomTable;