import { Node } from "@tiptap/core";
import { goToTag } from "./utils";
const DocumentNode = Node.create({
    name: "doc",
    topLevelNode: true,
    content: "pageNode+",

    addCommands() {
        return {
            toggleAllHeaderFooter: (payload) => ({ tr, chain, dispatch }) => {
                let { doc } = tr;
                let resolvedDocPosition = doc.resolve(0);
                let success = true;
                let countPagesWithout = getNumberOfPagesWithoutFooterOrHeader(
                    doc
                );
                if (countPagesWithout > 0) {
                    for (let idx = doc.childCount - 1; idx >= 0; idx--) {
                        let absolutePosBeforePage = resolvedDocPosition.posAtIndex(
                            idx,
                            0
                        );
                        let resolvedPagePosition = doc.resolve(
                            absolutePosBeforePage + 1
                        );
                        let absoluteBeforeHeaderPos = resolvedPagePosition.start(
                            1
                        );

                        let hasHeader = doc.child(idx).attrs.displayHeader;
                        let hasFooter = doc.child(idx).attrs.displayFooter;

                        if (!hasHeader || !hasFooter) {
                            success =
                                success &&
                                chain()
                                    .setTextSelection(absoluteBeforeHeaderPos)
                                    .toggleHeaderFooter(payload)
                                    .run();
                        }
                    }
                }
                if (countPagesWithout === 0) {
                    for (let idx = doc.childCount - 1; idx >= 0; idx--) {
                        let absolutePosBeforePage = resolvedDocPosition.posAtIndex(
                            idx,
                            0
                        );
                        let resolvedPagePosition = doc.resolve(
                            absolutePosBeforePage + 1
                        );
                        let absoluteBeforeHeaderPos = resolvedPagePosition.start(
                            1
                        );
                        let absoluteAfterFooterPos = resolvedPagePosition.end(
                            1
                        );
                        let resolvedPosInHeader = doc.resolve(
                            absoluteBeforeHeaderPos + 1
                        );
                        let absoluteAfterHeaderPos = resolvedPosInHeader.after(
                            2
                        );
                        let resolvedPosInFooter = doc.resolve(
                            absoluteAfterFooterPos - 1
                        );
                        let absoluteBeforeFooterPos = resolvedPosInFooter.before(
                            2
                        );
                        success =
                            success &&
                            chain()
                                .setTextSelection(absoluteBeforeHeaderPos)
                                .updateAttributes("pageNode", {
                                    displayFooter: false,
                                })
                                .updateAttributes("pageNode", {
                                    displayHeader: false,
                                })
                                .command(({ tr }) => {
                                    if (dispatch) {
                                        tr.delete(
                                            absoluteBeforeFooterPos,
                                            absoluteAfterFooterPos
                                        );
                                    }
                                    return true;
                                })
                                .command(({ tr }) => {
                                    if (dispatch) {
                                        tr.delete(
                                            absoluteBeforeHeaderPos,
                                            absoluteAfterHeaderPos
                                        );
                                    }
                                    return true;
                                })
                                .run();
                    }
                }
                return success;
            },

            reinsertContent: () => ({ commands, state }) => {
                let { doc } = state;
                let content = getContentArray(doc);
                let success = true;
                success =
                    success &
                    commands.setContent({
                        type: "pageNode",
                        content: [...content],
                    });
                return success;
            },

            fixOverflow: ({ allowedHeight }) => ({ view, state, commands }) => {
                let currentContent = getContentArray(state.doc);
                let newContent = [];
                let pageDomNode = view.nodeDOM(0);
                if (pageDomNode.hasChildNodes()) {
                    let children = pageDomNode.children;
                    let firstElIdx = 0;
                    for (let idx = 0; idx < children.length; idx++) {
                        let child = children[idx];
                        let offsetBottom = child.offsetTop + child.offsetHeight;
                        let lastOffset = children[firstElIdx].offsetTop;
                        if (offsetBottom - lastOffset > allowedHeight) {
                            if (firstElIdx === idx) {
                                newContent.push({
                                    type: "pageNode",
                                    attrs: { class: "page landscape" },
                                    content: currentContent.slice(
                                        firstElIdx,
                                        firstElIdx + 1
                                    ),
                                });
                            } else {
                                newContent.push({
                                    type: "pageNode",
                                    attrs: {
                                        class:
                                            firstElIdx + 1 === idx
                                                ? "page landscape"
                                                : "page",
                                    },
                                    content: currentContent.slice(
                                        firstElIdx,
                                        idx
                                    ),
                                });

                                if (idx === children.length - 1) {
                                    newContent.push({
                                        type: "pageNode",
                                        attrs: { class: "page" },
                                        content: currentContent.slice(
                                            idx,
                                            idx + 1
                                        ),
                                    });
                                }
                            }
                            firstElIdx = idx;
                        } else {
                            if (idx === children.length - 1) {
                                newContent.push({
                                    type: "pageNode",
                                    attrs: { class: "page" },
                                    content: currentContent.slice(
                                        firstElIdx,
                                        idx + 1
                                    ),
                                });
                            }
                        }
                    }
                }
                return commands.setContent(newContent);
            },
            isFullHeaderAndFooter: () => ({ tr }) => {
                let { doc } = tr;
                let countPagesWithout = getNumberOfPagesWithoutFooterOrHeader(
                    doc
                );
                return countPagesWithout === 0;
            },
            goToTag:(id,cb) => goToTag(id, cb)
        };
    },

    addKeyboardShortcuts() {
        return {
            "Mod-a": () => {},
        };
    },
});

function getContentArray(doc) {
    let contentArray = [];
    for (let idx = 0; idx < doc.childCount; idx++) {
        let pageNode = doc.child(idx);
        let hasHeader = pageNode.firstChild
            ? pageNode.firstChild.type.name === "pageHeader"
            : false;

        let hasFooter = pageNode.lastChild
            ? pageNode.lastChild.type.name === "pageFooter"
            : false;
        let contentIdx = 0;
        let contentCount = pageNode.childCount;
        if (hasHeader) contentIdx = 1;
        if (hasFooter) contentCount--;

        for (contentIdx; contentIdx < contentCount; contentIdx++) {
            contentArray.push(pageNode.child(contentIdx).toJSON());
        }
    }
    return contentArray;
}

function getNumberOfPagesWithoutFooterOrHeader(doc) {
    let countPagesWithout = 0;
    doc.forEach(
        (pageNode) =>
            (countPagesWithout +=
                pageNode.attrs.displayHeader && pageNode.attrs.displayFooter
                    ? 0
                    : 1)
    );
    return countPagesWithout;
}

export default DocumentNode;
