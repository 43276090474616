import { VueNodeViewRenderer } from "@tiptap/vue-2";
import { Node, mergeAttributes } from "@tiptap/core";
import HeaderView from "../nodeViews/HeaderView";
const PageHeader = Node.create({
    name: "pageHeader",
    content: "block+",
    group: "headerFooter",
    isolating: true,
    selectable: false,
    defining: false,
    allowGapCursor: false,

    renderHTML({ HTMLAttributes }) {
        return [
            "div",
            mergeAttributes(HTMLAttributes, { class: "page-header" }),
            0,
        ];
    },

    parseHTML() {
        return [
            {
                tag: "div",
                getAttrs: (node) => node.className.split(" ").includes("page-header") ? {} : false,
            },
        ];
    },
    addNodeView() {
        return VueNodeViewRenderer(HeaderView);
    },
});

export default PageHeader;
